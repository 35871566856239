import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { addAdmin, deleteAdminById, editAdmin, getAllAdmins, getAllDeletedAdmins, restoreAdminById } from '../apis/roleManagement/AdminManagement';
import { createNewRole, deleteRoleById, getAllDeletedRoles, getAllRolesList, restoreRoleById, updateExistingRole } from '../apis/roleManagement/RoleAPI';
import { editFunctionDetail, getAllFunctions } from '../apis/roleManagement/RoleFunctionAPI';
import { useLogin } from './AdminLoginContext';

// create global login context
export const RMContext = createContext();

export function RMProvider({ children }) {

    const { token, logout, setLoginError } = useLogin();
    const [functionList, setFunctionList] = useState("");
    const [roleUserList, setRoleUserList] = useState("");
    const [arFunction, setarFunction] = useState([]);
    const [removeFunction, setRemoveFunction] = useState([]);
    const [editFunction, setEditFunction] = useState(false)
    const [functionDetail, setFunctionDetail] = useState({
        "edit": false,
        "data": {}
    });
    const [editRole, setEditRole] = useState({
        "edit": false,
        "data": {}
    });
    const [userDetail, showUserDetail] = useState(false)
    const [adminList, setAdminList] = useState("")
    const [updateAdmin, setUpdateAdmin] = useState(false)
    const [createAdminRoleId, setCreateAdminRoleId] = useState("")
    const [editAdminDetail, setEditAdminDetail] = useState({
        "edit": false,
        "data": {}
    });
    const [showDeletedRoles, setShowDeletedRoles] = useState(false)
    const [deletedRoles, setDeletedRoles] = useState([])
    const [showDeletedAdmins, setShowDeletedAdmins] = useState(false)
    const [deletedAdmins, setDeletedAdmins] = useState([])

    // get all functions
    async function getFunctionList() {
        let response = await getAllFunctions(token);
        if (response && response.status === 200) {
            // setFunctionList(response.data);
            let result = {};
            let index = 1000;
            response.data.forEach(element => {
                if (element.function && ((element.function.includes(":") && result[element.function.split(":")[0]]) || result[element.function])) {
                    result[element.function.includes(":") ? element.function.split(":")[0] : element.function]['subFunctions'].push(element);
                } else if (element.function) {
                    result[element.function.includes(":") ? element.function.split(":")[0] : element.function] = {}
                    result[element.function.includes(":") ? element.function.split(":")[0] : element.function]['subFunctions'] = [element];
                    result[element.function.includes(":") ? element.function.split(":")[0] : element.function]['id'] = index += 1;
                }
            });
            setFunctionList(result);
        } else if (response && response.status === 401) {
            logout()
            setLoginError(configs.unauthorizedErrorMessage)
        } else { }
    }

    // edit function detail
    async function editFunctions(extraName, description) {
        try {
            let response = await editFunctionDetail(token, functionDetail.data.id, extraName, description);
            if (response && response.status === 200) {
                setFunctionDetail({
                    "edit": false,
                    "data": {}
                });
                getFunctionList()
                return "success";
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get all roles with users
    async function getAllRoleUsersList() {
        try {
            let response = await getAllRolesList(token);
            if (response && response.status === 200) {
                setRoleUserList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // create/update  role
    async function updateRoleDetail(name, description) {
        try {
            let response;
            let functions = arFunction.filter(id => !removeFunction.includes(id));
            if (editRole.edit) {
                response = await updateExistingRole(token, name, description, functions, editRole.data.id);
            } else {
                response = await createNewRole(token, name, description, functions);
            }
            if (response && response.status === 200) {
                await getAllRoleUsersList()
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete role
    async function deleteRole(roleId) {
        try {
            let response = await deleteRoleById(token, roleId);
            if (response && response.status === 200) {
                await getAllRoleUsersList()
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get all deleted roles
    async function getDeletedRoleList() {
        try {
            let response = await getAllDeletedRoles(token);
            if (response && response.status === 200) {
                setDeletedRoles(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // restore roles 
    async function restoreRole(roleId) {
        try {
            let response = await restoreRoleById(token, roleId);
            if (response && response.status === 200) {
                await getDeletedRoleList()
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // get admins
    async function getAllAdmin() {
        try {
            let response = await getAllAdmins(token);
            if (response && response.status === 200) {
                setAdminList(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // create new admin 
    async function createAdmin(name, email, password, role, mobile) {
        try {
            let response;
            if (editAdminDetail.edit) {
                response = await editAdmin(token, name, email, password, role, mobile, editAdminDetail.data.id)
            }
            else {
                response = await addAdmin(token, name, email, password, role, mobile);
            }
            if (response && response.status === 200) {
                await getAllAdmin()
                await getAllRoleUsersList()
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // delete admin 
    async function deleteAdmin(adminId) {
        try {
            let response = await deleteAdminById(token, adminId);
            if (response && response.status === 200) {
                await getAllAdmin()
                await getAllRoleUsersList()
                setEditAdminDetail({
                    "edit": false,
                    "data": {}
                })
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get all deleted admins
    async function getDeletedAdminList() {
        try {
            let response = await getAllDeletedAdmins(token);
            if (response && response.status === 200) {
                setDeletedAdmins(response.data)
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else { }
        } catch (error) {

        }
    }

    // restore admin 
    async function restoreAdmin(adminId) {
        try {
            let response = await restoreAdminById(token, adminId);
            if (response && response.status === 200) {
                await getDeletedAdminList()
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // export global context
    return (
        <RMContext.Provider
            value={{
                arFunction,
                setarFunction,
                removeFunction,
                setRemoveFunction,
                editFunction,
                setEditFunction,
                functionDetail,
                setFunctionDetail,
                editFunctions,
                functionList,
                getFunctionList,
                roleUserList,
                setRoleUserList,
                getAllRoleUsersList,
                updateRoleDetail,
                editRole,
                setEditRole,
                deleteRole,
                userDetail,
                showUserDetail,
                adminList,
                setAdminList,
                getAllAdmin,
                updateAdmin,
                setUpdateAdmin,
                createAdmin,
                createAdminRoleId,
                setCreateAdminRoleId,
                editAdminDetail,
                setEditAdminDetail,
                deleteAdmin,
                showDeletedRoles,
                setShowDeletedRoles,
                getDeletedRoleList,
                deletedRoles,
                setDeletedRoles,
                showDeletedAdmins,
                setShowDeletedAdmins,
                deletedAdmins,
                setDeletedAdmins,
                getDeletedAdminList,
                restoreRole,
                restoreAdmin
            }}
        >
            {children}
        </RMContext.Provider>
    );
}

// return role management context
export function useRMContext() {
    return useContext(RMContext);
}