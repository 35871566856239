import axios from "axios";
import { configs } from "../../../assets/Config";

// get all notification functions
export function getNotificationFunctions(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/allNotificationSettings`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update function send type
export function updateNotificationFunctionDetail(token, notificationId, value, type) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "notification_id": notificationId,
                "value": value,
                "type": type
            }
            var config = {
                method: 'patch',
                url: `${process.env.REACT_APP_BASEURL}/admin/notificationmanagement/updateNotificationSetting`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp broadcast category list
export function getBroadcastCategory(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/broadcast/categoryList`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// send whatsapp broadcast
export function sendWhatsappBroadcast(token, broadcastCategory, template_name, head_variable, body_variable, utm) {
    return new Promise(async (resolve, reject) => {
        try {
            var data = {
                "broadcast": broadcastCategory,
                "template_name": template_name,
                "head_variable": head_variable,
                "body_variable": body_variable,
                "utm": utm
            }
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/broadcast/whatsapp`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp broadcast history
export function getWhatsappBroadcastHistory(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/broadcast/whatsapp`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp broadcast history detail
export function getWhatsappBroadcastHistoryDetail(token, broadcastId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/broadcast/whatsapp/${broadcastId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp response messages
export function getWhatsappMessages(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/data`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update message response status
export function updateMessageResponseStatus(token, messageId) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                id: messageId
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/markAsResponded`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// update message comment
export function updateMessageComment(token, messageId, comment) {
    return new Promise(async (resolve, reject) => {
        try {
            let data = JSON.stringify({
                id: messageId,
                comment: comment
            })
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/comment`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp contacts
export function getWhatsappContacts(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/contact`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get whatsapp message by contact
export function getWhatsappContactMessages(token, mobile) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/message/${mobile}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// send whatsapp message to contact
export function sendWhatsappContactMessages(token, name, mobile, message) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/message`,
                headers: {
                    'Authorization': `Bearer ${token}`,

                },
                data: JSON.stringify({
                    phone_number: mobile,
                    message: message.trim(),
                    name: name
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// mark whatsapp message as read
export function markWhatsappMessageReadByContact(token, mobile) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/whatsapp/message/read`,
                headers: {
                    'Authorization': `Bearer ${token}`,

                },
                data: JSON.stringify({
                    phone_number: mobile
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}