import React from 'react';
import { createContext, useContext, useState } from 'react';
import { configs } from '../../assets/Config';
import { useLogin } from './AdminLoginContext';
import { getBroadcastCategory, getNotificationFunctions, getWhatsappBroadcastHistory, getWhatsappBroadcastHistoryDetail, getWhatsappContactMessages, getWhatsappContacts, getWhatsappMessages, sendWhatsappBroadcast, updateMessageComment, updateMessageResponseStatus, updateNotificationFunctionDetail } from '../apis/notificationManagement/NMAPI';
import { getBellTemplate, getEmailTemplate, getMobileTemplate, getNotificationTemplateFunctions, getWhatsappTemplate, updateBellTemplate, updateEmailTemplate, updateMobileTemplate, updateWhatsappTemplate } from '../apis/notificationManagement/NMTAPI';

// create notification management context
export const NotificationContext = createContext();


export function NotificationProvider({ children }) {

    const { token, setLoginError, logout } = useLogin()

    const [nmFunction, setNMFunction] = useState("");
    const [nmTempFunction, setNMTempFunction] = useState("");
    const [emailTemplate, setEmailTemplate] = useState("")
    const [mobileTemplate, setMobileTemplate] = useState("")
    const [bellTemplate, setBellTemplate] = useState("")
    const [whatsappTemplate, setWhatsappTemplate] = useState("")
    const [templateId, setTemplateId] = useState("")
    const [broadcastList, setBroadcastList] = useState("")
    const [broadCastHistory, setbroadCastHistory] = useState("")
    const [whatsappMessages, setWhatsappMessages] = useState("")
    const [allContacts, setAllContacts] = useState("");
    const [contacts, setContacts] = useState("");



    // get notification functions
    async function getNotificationFunction() {
        try {
            let response = await getNotificationFunctions(token)
            if (response && response.status === 200) {
                setNMFunction(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update notification functions
    async function updateNotificationFunction(notificationId, value, type) {
        try {
            let response = await updateNotificationFunctionDetail(token, notificationId, value, type)
            if (response && response.status === 200) {
                getNotificationFunction()
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get notification template functions
    async function getNotificationTemplateFunction() {
        try {
            let response = await getNotificationTemplateFunctions(token)
            if (response && response.status === 200) {
                setNMTempFunction(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get email template
    async function getEmailTemplateById() {
        try {
            let response = await getEmailTemplate(token, templateId)
            if (response && response.status === 200) {
                setEmailTemplate(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update email template
    async function updateEmailTemplateById(subject, template) {
        try {
            let response = await updateEmailTemplate(token, templateId, subject, template)
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get mobile template
    async function getMobileTemplateById() {
        try {
            let response = await getMobileTemplate(token, templateId)
            if (response && response.status === 200) {
                setMobileTemplate(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update mobile template
    async function updateMobileTemplateById(template) {
        try {
            let response = await updateMobileTemplate(token, templateId, template)
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get bell template
    async function getBellTemplateById() {
        try {
            let response = await getBellTemplate(token, templateId)
            if (response && response.status === 200) {
                setBellTemplate(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update bell template
    async function updateBellTemplateById(heading, description) {
        try {
            let response = await updateBellTemplate(token, templateId, heading, description)
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get whatsapp template
    async function getWhatsappTemplateById() {
        try {
            let response = await getWhatsappTemplate(token, templateId)
            if (response && response.status === 200) {
                setWhatsappTemplate(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update whatsapp template
    async function updateWhatsappTemplateById(name, head_variable, body_variable) {
        try {
            let response = await updateWhatsappTemplate(token, templateId, name, head_variable, body_variable)
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get whatsapp broadcast category
    async function getWhatsappBroadcastCategory() {
        try {
            let response = await getBroadcastCategory(token)
            if (response && response.status === 200) {
                setBroadcastList(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // send whatsapp broadcast
    async function sendWhatsappBroadcastByCategory(broadcastCategory, template_name, head_variable, body_variable, utm) {
        try {
            let response = await sendWhatsappBroadcast(token, broadcastCategory, template_name, head_variable, body_variable, utm)
            if (response && response.status === 200) {
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get whatsapp broadcast history
    async function whatsappBroadcastHistory() {
        try {
            let response = await getWhatsappBroadcastHistory(token)
            if (response && response.status === 200) {
                setbroadCastHistory(response.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get whatsapp broadcast history detail
    async function whatsappBroadcastHistoryDetail(broadcastId) {
        try {
            let response = await getWhatsappBroadcastHistoryDetail(token, broadcastId)
            if (response && response.status === 200) {
                return {
                    success: "success",
                    data: response.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }


    // get whatsapp response messages
    async function whatsappResponseMessages() {
        try {
            let response = await getWhatsappMessages(token)
            if (response && response.status === 200) {
                setWhatsappMessages(response.data.data)
                return "success"
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // get whatsapp contacts
    async function whatsappContacts() {
        try {
            let response = await getWhatsappContacts(token)
            if (response && response.status === 200) {
                setAllContacts(response.data.data);
                return {
                    success: 'success',
                    data: response.data.data
                }
            } else if (response && response.status === 401) {
                logout()
                setLoginError(configs.unauthorizedErrorMessage)
            } else {
                return response.data.error
            }
        } catch (error) {

        }
    }

    // update whatsapp message responded status
    async function changeMessageStatus(messageId) {
        try {
            let response = await updateMessageResponseStatus(token, messageId);
            if (response && response.status === 200) {
                return true;
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
        }
    }

    // update whatsapp message comment
    async function changeMessageComment(messageId, comment) {
        try {
            let response = await updateMessageComment(token, messageId, comment);
            if (response && response.status === 200) {
                return "success";
            } else if (response && response.status === 401) {
                logout();
                setLoginError(configs.unauthorizedErrorMessage);
            } else {
                return false
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <NotificationContext.Provider
            value={{
                nmFunction,
                setNMFunction,
                getNotificationFunction,
                updateNotificationFunction,
                nmTempFunction,
                getNotificationTemplateFunction,
                emailTemplate,
                setEmailTemplate,
                getEmailTemplateById,
                mobileTemplate,
                setMobileTemplate,
                getMobileTemplateById,
                bellTemplate,
                setBellTemplate,
                getBellTemplateById,
                whatsappTemplate,
                setWhatsappTemplate,
                getWhatsappTemplateById,
                templateId,
                setTemplateId,
                updateEmailTemplateById,
                updateMobileTemplateById,
                updateBellTemplateById,
                updateWhatsappTemplateById,
                broadcastList,
                setBroadcastList,
                getWhatsappBroadcastCategory,
                sendWhatsappBroadcastByCategory,
                whatsappBroadcastHistory,
                broadCastHistory,
                whatsappBroadcastHistoryDetail,
                whatsappMessages,
                whatsappResponseMessages,
                changeMessageStatus,
                changeMessageComment,
                whatsappContacts,
                allContacts,
                setAllContacts,
                contacts,
                setContacts

            }}
        >
            {children}
        </NotificationContext.Provider>)

}

export function useNMContext() {
    return useContext(NotificationContext)
}
