import axios from "axios";
import { configs } from "../../../assets/Config";
import FormData from 'form-data';

// upload csv to server
export function uploadCSVDoc(token, file, dep) {
    return new Promise(async (resolve, reject) => {
        try {

            var data = new FormData();
            data.append(dep ? "depository_inventory" : "inventory", file, "inventory.csv")

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/${dep ? "inventory/depository" : "buy/inventory"}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}


// download csv from server
export function downloadCSVDoc(token, dep) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/${dep ? "inventory/depository" : "buy/inventory"}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get inventory analytics
export function getInventoryAnalytics(token) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/inventory/analytics`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get open orders by company and type
export function getCompanyOpenOrders(token, company, type) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/inventory/open-orders?company_id=${company}&type=${type}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get inventory by company
export function getInventoryByCompany(token, company) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/inventory/manual/${company}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// manual update inventory by company
export function updateInventoryByCompany(token, company, allow_online, price1, price2, price3, price4, buyPrice, sellPrice) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/inventory/manual`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    company_id: company,
                    allow_online: allow_online,
                    price1: price1,
                    price2: price2,
                    price3: price3,
                    price4: price4,
                    buy_display_price: buyPrice,
                    sell_display_price: sellPrice
                })
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}