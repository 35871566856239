import React, { useEffect, useState } from "react";
import { configs, formatNumber } from "../../../assets/Config";
import { useLogin } from "../../../services/contexts/AdminLoginContext";
import LeftBarPage from "../../includes/dashboard/LeftBarPage";
import TopBarPage from "../../includes/dashboard/TopBarPage";
import "../../../assets/styles/BuyOrder.css"
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useKYCContext } from "../../../services/contexts/KYCContext";
import { useBuyOrderContext } from "../../../services/contexts/BuyOrdersContext";
import ToastPage from "../../includes/ToastPage";
import { useHistory } from "react-router";
import { useSellOrderContext } from "../../../services/contexts/SellOrdersContext";
import { useDistributorContext } from "../../../services/contexts/DistributorContext";
import { getInventoryByCompany, updateInventoryByCompany } from "../../../services/apis/shareManagement/InventoryAPI";

export default function ManualInventoryPage() {

    const { kycRequestersList, getKYCRequesters } = useKYCContext()
    const { getCompanyList, getCompanySlabsByCompanyId, manualOrderPunch } = useBuyOrderContext()
    const { sellManualOrderPunch } = useSellOrderContext()
    const { setCurrModule, setShowSuccess, allowedFunc, token, setLoginError, logout } = useLogin()


    const [companyId, setCompanyId] = useState("")
    const [buyPrice, setBuyPrice] = useState("")
    const [sellPrice, setSellPrice] = useState("")
    const [allowOnline, setAllowOnline] = useState("yes");
    const [inventory, setInventory] = useState("");
    const [companyList, setCompanyList] = useState("")
    const [error, setError] = useState("")

    const history = useHistory()

    useEffect(() => {
        try {
            if (allowedFunc.includes(configs.functionList['buy_module:update_inventory'])) {
                (async () => {
                    setCurrModule(configs.modules.manualInventoryUpdate)
                    let response = await getCompanyList()
                    if (response.success) {
                        setCompanyList(response.data)
                    } else {

                    }
                })()
            } else {
                return history.goBack()
            }
        } catch (error) {
            console.error(error)
        }
    }, [])

    const handleCompanyChange = async (e, value) => {
        try {
            setCompanyId(value.id)
            if (value && value.id) {
                let response = await getInventoryByCompany(token, value.id);
                if (response && response.status === 200) {
                    setInventory(response.data)
                } else if (response && response.status === 401) {
                    logout()
                    setLoginError(configs.unauthorizedErrorMessage)
                } else {
                    setError(response.data.error)
                }
            }
        } catch (error) {

        }
    }

    // manual order punch
    async function manualPunch() {
        try {
            document.getElementById("manualPunchBtn").classList.toggle("d-none")
            document.getElementById("manualPunchBtnLoader").classList.toggle("d-none")

            const red = /^[0-9]\d*(\.\d{1,2})?$/;
            if (!companyId) {
                setError("Please select a valid company.")
            } else if (!buyPrice.toString().match(red)) {
                setError("Please enter a valid buy price with only numbers.")
            } else if (!sellPrice.toString().match(red)) {
                setError("Please enter a valid sell price with only numbers.")
            } else {
                let response = await updateInventoryByCompany(token, companyId, allowOnline, buyPrice + (buyPrice * 0.03), buyPrice + (buyPrice * 0.02), buyPrice + (buyPrice * 0.01), buyPrice, buyPrice, sellPrice)
                if (response && response.status === 200) {
                    setBuyPrice("")
                    setSellPrice("")
                    setAllowOnline("yes")
                    setShowSuccess(true)
                } else {
                    setError(response.data.error)
                }
            }
            document.getElementById("manualPunchBtn").classList.toggle("d-none")
            document.getElementById("manualPunchBtnLoader").classList.toggle("d-none")
        } catch (error) {
            console.error(error)
        }
    }


    return (
        <>
            <TopBarPage />
            <div style={{ display: "flex" }}>
                <LeftBarPage />
                <div id="mpOrder" className="smPO-container">
                    <div className="col-12 text-left">
                        <p className="h3 text-custom-greyDark mb-0">Manual Inventory Update</p>
                    </div>
                    {companyList ?
                        <div className="smPOI-container">
                            <form action="">
                                <div className="form-row py-3">
                                    <div className="col-lg-6 form-group">
                                        <label>Company Name</label>
                                        {companyList ? <Autocomplete
                                            className="f-12"
                                            id="companyIP"
                                            placeholder="Compnay Name"
                                            getOptionLabel={option => option.name}
                                            onChange={handleCompanyChange}
                                            options={companyList}
                                            renderInput={(params) => <TextField {...params} placeholder="Company Name" className="f-12" />}
                                        /> : null}
                                    </div>
                                    <div className="col-lg-6 form-group">
                                        <label>Available Inventory Quantity: <b>{formatNumber(inventory && inventory.available_quantity ? inventory.available_quantity : "0")}</b></label>
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Allow Online (Disable Further Order)</label>
                                        <select name="" id="" className="form-control f-10 py-2 dropDown" value={allowOnline} onChange={(e) => {
                                            setAllowOnline(e.target.value)
                                        }}>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Buy Price</label>
                                        <input type="number" className="form-control f-10 py-2" placeholder="Buy Price" value={buyPrice.toString()} onChange={(e) => {
                                            setBuyPrice(parseFloat(e.target.value))
                                        }} />
                                    </div>
                                    <div className="col-lg-4 form-group">
                                        <label>Sell Price</label>
                                        <input type="number" className="form-control f-10 py-2" placeholder="Sell Price" value={sellPrice} onChange={(e) => {
                                            setSellPrice(e.target.value)
                                        }} />
                                    </div>

                                </div>
                                <div className="form-check p-0">
                                    <p className="text-danger" id="manualOrderPunchError">{error}</p>
                                </div>

                                <div className="col-12 d-flex justify-content-center mt-3">
                                    <button id="manualPunchBtn" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault()
                                        manualPunch()
                                    }}>Submit</button>
                                    <div id="manualPunchBtnLoader" className='animBtn d-none'><div className='gSignIn-Anim'></div></div>
                                </div>
                            </form>
                        </div>
                        : <div className="loader"></div>}

                </div>
                <ToastPage />
            </div >
        </>
    )
}