import axios from "axios";
import { configs } from "../../assets/Config";
import FormData from 'form-data';

// get all latest remark list
export function getAllLatestRemark(token) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/user/latest`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all remark list
export function getAllRemark(token, url) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm${url ? `/${url}` : ""}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get no rum users
export function getNoRMUser(token,) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/user/noRM`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all remark list by user
export function getAllLatestRemarkByUser(token, userId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/user/${userId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// get all remark list by rm
export function getAllLatestRemarkByRM(token, rmId) {
    return new Promise(async (resolve, reject) => {
        try {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/rm/${rmId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update news
export function createUpdateRemark(token, userId, relationshipManager, last_call_date, call_remark, investable_surplus, interested_in, editRemark) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = {
                user_id: userId,
                relationship_manager: relationshipManager,
                last_call_date: last_call_date,
                call_remark: call_remark,
                investable_surplus: investable_surplus,
                interested_in: interested_in
            }

            let method = 'POST'

            if (editRemark) {
                method = 'PATCH'
                // data['news_id'] = newsId
            } else {
                // data['company_id'] = companyId
            }

            var config = {
                method: method,
                url: `${process.env.REACT_APP_BASEURL}/admin/crm`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create or update remark for non exisitng user
export function createUpdateRemarkNonUser(token, name, email, mobile, relationshipManager, last_call_date, call_remark, investable_surplus, interested_in, editRemark) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = {
                name: name,
                email: email,
                mobile: mobile,
                relationship_manager: relationshipManager,
                last_call_date: last_call_date,
                call_remark: call_remark,
                investable_surplus: investable_surplus,
                interested_in: interested_in
            }

            let method = 'POST'

            if (editRemark) {
                method = 'PATCH'
                // data['news_id'] = newsId
            } else {
                // data['company_id'] = companyId
            }

            var config = {
                method: method,
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/non-user`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// create remark for order lead
export function createOrderLeadRmeark(token, customerId, name, email, mobile, companyName, Quantity, rate, cQuote, oQuote, aQuote, orderType, remark) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = {
                user_id: customerId,
                name: name,
                email: email,
                mobile: mobile,
                company_name: companyName,
                quantity: Quantity,
                price_per_share: rate,
                order_type: orderType,
                remark: remark,
                client_quote: cQuote,
                our_quote: oQuote,
                approved_quote: aQuote
            }

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/order-insight`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// edit remark for order lead
export function editOrderLeadRmeark(token, insightId, status, cQuote, oQuote, aQuote, remark) {
    return new Promise(async (resolve, reject) => {
        try {

            let data = {
                insight_id: insightId,
                status: status,
                remark: remark,
                client_quote: cQuote,
                our_quote: oQuote,
                approved_quote: aQuote
            }

            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/order-insight/status`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}

// remark history
export function getRemarkHistory(token, insightId) {
    return new Promise(async (resolve, reject) => {
        try {

            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_BASEURL}/admin/crm/order-insight-history/${insightId}`,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            };

            let res = await axios(config)
            resolve(res)
        } catch (err) {
            resolve(err?.response)
        }
    })
}